<template>
  <div class="price-box">
    <span v-if="showPricing">
      <strong>{{ $t('pricebook.monthly-rate') }}</strong>: <span data-name="monthlyRate">{{ formattedValue }}</span> ({{ $t('pricebook.excludes-tax') }})</span>
  </div>
</template>

<script>
import { moneyFilter } from '@/helpers.js'

export default {
  name: 'PriceBox',
  inject: ['disabledFeatures', 'isFeatureEnabled'],
  props: {
    monthlyRate: {
      type: Number,
      required: false,
      default: null,
    },
    currency: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    formattedValue() {
      return moneyFilter(this.monthlyRate, this.currency)
    },
    hasMonthlyRate() {
      return typeof this.monthlyRate === 'number' && isFinite(this.monthlyRate)
    },
    showPricing() {
      return this.hasMonthlyRate && this.currency && !this.disabledFeatures.showPrices && this.isFeatureEnabled('PRICING_VISIBLE')
    },
  },
}
</script>

<style lang="scss" scoped>
.price-box {
  margin: auto;
  margin-bottom: 1rem;
  width: fit-content;
  height: 2rem;
  color: var(--color-text-regular);
  font-size: 1.4rem;
  font-weight: 300;
}
</style>
