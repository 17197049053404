import { G_DIVERSITY_ZONE_BLUE, G_DIVERSITY_ZONE_NONE, G_DIVERSITY_ZONE_RED, G_PRODUCT_TYPE_MCR2, G_PRODUCT_TYPE_MVE } from '@/Globals.js'

/**
 * Calculate a diversity zone to place a service in
 * @param {String} productType Product type to calculate diversity for (for now only MCR2 and MVE is supported)
 * @param {Object} diversityZones Object containing diversity zone availability for each product
 * @returns Either a red or blue diversity zone
 */
export const calculateAutoDiversity = (productType, diversityZones) => {
  if (![G_PRODUCT_TYPE_MCR2, G_PRODUCT_TYPE_MVE].includes(productType)) return

  const productLowerCase = productType.toLocaleLowerCase()

  // If the location or product type has no diversity zones, return 'none'
  if (!diversityZones?.[productLowerCase] || !diversityZones?.[productLowerCase].length) {
    return G_DIVERSITY_ZONE_NONE
  }

  const redZones = diversityZones[productLowerCase].filter(zone => zone.name === G_DIVERSITY_ZONE_RED)
  const blueZones = diversityZones[productLowerCase].filter(zone => zone.name === G_DIVERSITY_ZONE_BLUE)

  // If there are either no red or blue zones then pick the opposite
  if (!redZones.length) return G_DIVERSITY_ZONE_BLUE
  if (!blueZones.length) return G_DIVERSITY_ZONE_RED

  // This formats the diversity zone array into red and blue arrays each containing list of max bandwidth / cpu cores
  const zoneSpeeds = diversityZones[productLowerCase].reduce((acc, zone) => {
    let speed
    productType === G_PRODUCT_TYPE_MCR2
      ? speed = zone.maxAvailableBandwidth
      : speed = zone.maxAvailableCpuCores

    acc[zone.name] = acc[zone.name] || []
    acc[zone.name].push(speed)

    return acc
  }, {})

  // Get the highest speed from each zone
  const maxRedZone = Math.max(...zoneSpeeds.red)
  const maxBlueZone = Math.max(...zoneSpeeds.blue)

  // If the speeds are different then return the one with the highest
  if (maxRedZone > maxBlueZone) {
    return G_DIVERSITY_ZONE_RED
  } else if (maxRedZone < maxBlueZone) {
    return G_DIVERSITY_ZONE_BLUE
  }

  // If get here then each zone has the same max speed so count how many times that max speed is present
  const redCount = zoneSpeeds.red.reduce((count, num) => {
    return num === maxRedZone ? count + 1 : count
  }, 0)
  const blueCount = zoneSpeeds.blue.reduce((count, num) => {
    return num === maxBlueZone ? count + 1 : count
  }, 0)

  // Now we can determine which zone has the greatest number of the highest speed
  const finalRed = maxRedZone * redCount
  const finalBlue = maxBlueZone * blueCount

  // Return the diversity zone with the highest number and count
  if (finalRed > finalBlue) {
    return G_DIVERSITY_ZONE_RED
  } else if (finalRed < finalBlue) {
    return G_DIVERSITY_ZONE_BLUE
  } else if (finalRed === finalBlue) {
    // Pick a diversity zone at random if they are equal
    return Math.random() < 0.5 ? G_DIVERSITY_ZONE_RED : G_DIVERSITY_ZONE_BLUE
  }
}
