<template>
  <section class="diversity-group">
    <!-- Heading -->
    <div role="banner"
      class="font-weight-500 text-align-center full-width mb-1">
      {{ $t('ports.port-diversity') }}
      <el-tooltip placement="top"
        :open-delay="500">
        <template #content>
          <i18n path="ports.diversity-explanation-html"
            tag="div">
            <template #link>
              <a href="https://docs.megaport.com/connections/port-diversity/"
                target="_blank">{{ $t('ports.port-diversity') }}</a>
            </template>
          </i18n>
        </template>
        <i class="fas fa-question-circle color-info popover-info-icon-end"
          aria-hidden="true" />
      </el-tooltip>
    </div>

    <!-- Cases where diversity can't be selected -->
    <div v-if="!portSpeed"
      role="alert"
      class="disclaimer mt-1"
      data-name="no-speed-selected">
      {{ $t('ports.select-speed-diversity') }}
    </div>
    <div v-else-if="!diversityAvailable"
      role="presentation">
      <div role="alert"
        class="disclaimer mt-1"
        data-name="no-diversity"
        v-html="diversityUnAvailableLocation" /> <!-- eslint-disable-line vue/no-v-html -->
    </div>
    <div v-else-if="addToLag"
      role="alert"
      data-name="add-to-lag"
      class="disclaimer mt-1">
      {{ $tc('ports.diversity-lag-add', addToLag) }}
    </div>
    <template v-else>
      <!-- Diversity selections -->
      <el-form-item :label="$t('services.diversity-option')"
        label-width="239px"
        data-name="diversity-option">
        <el-select :value="diversityOption"
          name="portDiversity"
          data-testid="diversity-option"
          class="min-width-220px"
          @change="diversityOptionChanged">
          <template #prefix>
            <template v-if="diversityOption === diversityOptions.keep">
              <i class="far fa-dot-circle ml-4px"
                :class="`diversity-color-${initialDiversityObject.diversityOption}`"
                aria-hidden="true" />
            </template>
          </template>
          <el-option :key="diversityOptions.none"
            :value="diversityOptions.none"
            :label="$t('general.none')"
            :data-name="diversityOptions.none" />
          <!-- Already created as part of a pair - make sure this option sticks around. -->
          <el-option v-if="[G_DIVERSITY_ZONE_BLUE, G_DIVERSITY_ZONE_RED].includes(initialDiversityObject.diversityOption) && !initialDiversityObject.diverseFrom"
            :key="diversityOptions.keep"
            :value="diversityOptions.keep"
            :label="$t('services.diversity-zone')"
            :data-name="diversityOptions.keep"
            data-testid="diversity-option-keep">
            <i class="far fa-dot-circle"
              :class="`diversity-color-${initialDiversityObject.diversityOption}`"
              aria-hidden="true" />
            {{ $t('services.diversity-zone') }}
          </el-option>
          <el-option v-else
            :key="diversityOptions.pair"
            :value="diversityOptions.pair"
            :data-name="diversityOptions.pair"
            :label="$t('services.create-diverse-pair')" />
          <el-option :key="diversityOptions.existing"
            :value="diversityOptions.existing"
            :data-name="diversityOptions.existing"
            data-testid="diversity-option-existing"
            :disabled="!availablePorts.length"
            :label="$t('ports.diverse-from-existing')" />
        </el-select>
      </el-form-item>

      <!-- Port pair inputs -->
      <el-collapse-transition>
        <section v-if="diversityOption === diversityOptions.pair">
          <el-form-item prop="productName"
            label-width="239px"
            :label="$t('ports.first-port-name', { thing: $t(isLag ? 'productNames.lag' : 'productNames.port')})">
            <el-input :value="firstPortName"
              data-name="first-port-name"
              data-demo="First Port"
              @input="firstPortNameChanged" />
          </el-form-item>
          <el-form-item prop="secondPortName"
            label-width="239px"
            :label="$t('ports.second-port-name', { thing: $t(isLag ? 'productNames.lag' : 'productNames.port')})">
            <el-input :value="secondPortName"
              data-name="second-port-name"
              data-demo="Second Port"
              @input="secondPortNameChanged" />
          </el-form-item>
          <div role="alert"
            class="disclaimer mt-1"
            data-name="diverse-pair">
            {{ $t('ports.diverse-pair', { things: $t(isLag ? 'productNames.lags' : 'productNames.ports')}) }}
          </div>
        </section>
      </el-collapse-transition>

      <!-- Diverse from existing port selection -->
      <el-collapse-transition>
        <el-form-item v-if="diversityOption === diversityOptions.existing"
          label-width="239px"
          :label="$t('ports.port-diverse-from')">
          <el-select :value="diverseFromPort"
            class="min-width-220px"
            data-name="port-selector"
            data-testid="port-selector"
            @change="diverseFromPortChanged">
            <el-option v-for="port in availablePorts"
              :key="port.productUid"
              :label="port.productName"
              :value="port.productUid"
              data-testid="port-selector-option">
              {{ port.productName }}
              <i class="far fa-dot-circle"
                :class="`diversity-color-${diversityZoneOfPort(port.productUid)}`"
                aria-hidden="true" />
            </el-option>
          </el-select>
        </el-form-item>
      </el-collapse-transition>
    </template>
  </section>
</template>

<script>
// Need to import these directly for use in the validator below
import {
  G_DIVERSITY_ZONE_BLUE,
  G_DIVERSITY_ZONE_RED,
  G_DIVERSITY_ZONE_PAIR,
} from '@/Globals.js'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'PortDiversitySettings',

  props: {
    initialDiversityObject: {
      type: Object,
      required: true,
      validator(value) {
        return Object.hasOwn(value, 'diversityOption') &&
          Object.hasOwn(value, 'diverseFrom') &&
          (value.diversityOption === null || [G_DIVERSITY_ZONE_BLUE, G_DIVERSITY_ZONE_RED, G_DIVERSITY_ZONE_PAIR].includes(value.diversityOption))
      },
    },
    diversityObject: {
      type: Object,
      required: true,
      validator(value) {
        return Object.hasOwn(value, 'diversityOption') &&
          Object.hasOwn(value, 'diverseFrom') &&
          Object.hasOwn(value, 'firstPortName') &&
          Object.hasOwn(value, 'secondPortName') &&
          (value.diversityOption === null || [G_DIVERSITY_ZONE_BLUE, G_DIVERSITY_ZONE_RED, G_DIVERSITY_ZONE_PAIR].includes(value.diversityOption))
      },
    },
    isLag: {
      type: Boolean,
      required: false,
      default: false,
    },
    addToLag: {
      type: Number,
      required: false,
      default: 0,
    },
    locationId: {
      type: Number,
      required: true,
    },
    portSpeed: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  data() {
    return {
      diversityOption: null,
      diverseFromPort: this.diversityObject.diverseFrom,
      firstPortName: this.diversityObject.firstPortName,
      secondPortName: this.diversityObject.secondPortName,
      diversityOptions: {
        none: 'none',
        pair: 'pair',
        keep: 'keep',
        existing: 'existing',
      },
    }
  },

  computed: {
    ...mapState('Services', ['locations']),
    ...mapGetters('Auth', ['isPartnerSupported']),
    ...mapGetters('Services', ['myPorts']),
    diversityUnAvailableLocation() {
      return this.isPartnerSupported ? this.$t('partner.diversity-unavailable-location') : this.$t('ports.diversity-unavailable-location')
    },
    /**
     * Work out which of the options to select based on the current values. The available
     * options go in hand with this by looking at the initial diversity settings.
     */
    initialDiversityOption() {
      if (!this.diversityObject.diversityOption) {
        return this.diversityOptions.none
      }
      if (this.diversityObject.diversityOption === G_DIVERSITY_ZONE_PAIR) {
        return this.diversityOptions.pair
      }
      if ([G_DIVERSITY_ZONE_BLUE, G_DIVERSITY_ZONE_RED].includes(this.diversityObject.diversityOption) && !this.diversityObject.diverseFrom) {
        return this.diversityOptions.keep
      }
      return this.diversityOptions.existing
    },
    diversityAvailable() {
      const locationData = this.locations.find(loc => loc.id === this.locationId)
      const diversityData = locationData?.diversityZones?.megaport
      if (diversityData && this.portSpeed) {
        const requiredSpeed = this.portSpeed / 1000
        const availableZones = diversityData.filter(item => item.speed === requiredSpeed)
        if (availableZones.length > 1) {
          return true
        }
      }
      return false
    },
    availablePorts() {
      return this.myPorts.filter(port => {
        return port.locationId === this.locationId && !port.virtual && (!port.aggregationId || port.lagPrimary) && port.provisioningStatus !== this.G_PROVISIONING_DESIGN
      })
    },
  },

  created() {
    this.diversityOption = this.initialDiversityOption
  },

  methods: {
    emitUpdate() {
      const state = {
        diversityOption: null,
        diverseFrom: null,
        firstPortName: null,
        secondPortName: this.secondPortName,
      }

      switch (this.diversityOption) {
        case this.diversityOptions.none:
          break
        case this.diversityOptions.pair:
          state.diversityOption = G_DIVERSITY_ZONE_PAIR
          state.firstPortName = this.firstPortName
          break
        case this.diversityOptions.keep:
          state.diversityOption = this.initialDiversityObject.diversityOption
          break
        case this.diversityOptions.existing:
          state.diverseFrom = this.diverseFromPort
          if (this.diversityZoneOfPort(state.diverseFrom) === G_DIVERSITY_ZONE_BLUE) {
            state.diversityOption = G_DIVERSITY_ZONE_RED
          } else {
            state.diversityOption = G_DIVERSITY_ZONE_BLUE
          }
          break
        default:
          throw new Error(`Invalid diversity option: ${this.diversityOption}`)
      }

      this.$emit('update', state)
    },
    diversityZoneOfPort(productUid) {
      const otherPort = this.myPorts.find(port => port.productUid === productUid)
      return (otherPort.diversityZone === G_DIVERSITY_ZONE_BLUE) ? G_DIVERSITY_ZONE_BLUE : G_DIVERSITY_ZONE_RED
    },
    diversityOptionChanged(value) {
      this.diversityOption = value
      if (value === this.diversityOptions.existing) {
        this.diverseFromPort = this.availablePorts[0].productUid
      } else {
        this.diverseFromPort = null
      }
      if (value === this.diversityOptions.pair) {
        this.firstPortName = this.diversityObject.firstPortName
        this.secondPortName = this.diversityObject.secondPortName
      }
      this.emitUpdate()
    },
    diverseFromPortChanged(value) {
      this.diverseFromPort = value
      this.emitUpdate()
    },
    firstPortNameChanged(value) {
      this.firstPortName = value
      this.emitUpdate()
    },
    secondPortNameChanged(value) {
      this.secondPortName = value
      this.emitUpdate()
    },

  },
}
</script>

<style scoped>
.diversity-group {
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
  padding: 1rem 1.4rem 0 1rem;
  margin-bottom: 1rem;
  color: var(--color-text-primary);
}
.disclaimer {
  background-color: var(--color-white);
  border: 1px solid var(--color-info);
  border-radius: var(--border-radius-base);
  padding: 1rem;
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color-text-regular);
  margin-bottom: 1.3rem;
}

.min-width-220px {
  min-width: 220px;
}
</style>
