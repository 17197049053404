<template>
  <div class="diversity-filter">
    <span class="diversity__heading">{{ $t('services.diversity-zone') }}:</span>
    <el-tooltip placement="top"
      :open-delay="500">
      <i class="fas fa-question-circle color-info popover-info-icon"
        aria-hidden="true" />
      <template #content>
        <i18n path="services.diversity-location-tooltip"
          tag="p"
          class="tooltip-p">
          <template #link>
            <a href="https://docs.megaport.com/mcr/mcr-mve-diversity/"
              target="_blank">{{ $t('general.documentation') }}</a>
          </template>
        </i18n>
      </template>
    </el-tooltip>
    <el-radio-group v-model="selectedZone"
      size="small"
      @change="$emit('input', selectedZone)">
      <!-- Auto -->
      <el-radio-button :label="G_DIVERSITY_ZONE_AUTO"
        class="dz-dot">
        <img src="@/assets/images/auto-diversity.svg"
          :alt="$t('images.auto-diversity')"
          width="12px"
          height="12px"
          class="mr-0-5"
          aria-hidden="true">
        <span>{{ $t('general.auto') }}</span>
      </el-radio-button>

      <!-- Red -->
      <el-radio-button :label="G_DIVERSITY_ZONE_RED"
        class="dz-dot">
        <i class="far fa-dot-circle diversity-zone-icon diversity-color-red mr-0-5"
          :title="$t(`services.red-zone`)"
          aria-hidden="true" />
        <span>{{ $t('general.red') }}</span>
      </el-radio-button>

      <!-- Blue -->
      <el-radio-button :label="G_DIVERSITY_ZONE_BLUE"
        class="dz-dot">
        <i class="far fa-dot-circle diversity-zone-icon diversity-color-blue mr-0-5"
          :title="$t(`services.blue-zone`)"
          aria-hidden="true" />
        <span>{{ $t('general.blue') }}</span>
      </el-radio-button>
    </el-radio-group>
  </div>
</template>

<script>
import { G_DIVERSITY_ZONE_AUTO } from '@/Globals'

export default {
  name: 'DiversityFilter',

  props: {
    value: {
      type: String,
      required: false,
      default: G_DIVERSITY_ZONE_AUTO,
    },
  },

  emits: ['input'],

  data() {
    return {
      selectedZone: this.value,
    }
  },
}
</script>

<style lang="scss" scoped>
.diversity-filter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.75rem;

  .diversity__heading {
    font-weight: 400;
    color: var(--color-text-regular);
  }

  img {
    vertical-align: bottom;
  }

  .diversity-zone-icon {
    font-size: 1.2rem;
  }

  .popover-info-icon {
    padding: 0;
  }
}
</style>
